body {
  margin: 0;
}

body, button, input {
  font-family: "Suisse Int'l";
}

header {
  margin: 10px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
header a {
  color: currentColor;
  text-decoration: none;
  position: relative;
}
header a::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 100%;
  height: 1px;
  background-color: currentColor;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  pointer-events: none;
}
header a::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 1px;
  opacity: 0.2;
  background-color: currentColor;
  pointer-events: none;
}
header a:hover::before {
  right: 0;
}
header h1 {
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}
header nav {
  font-size: 14px;
}
header nav a {
  margin-left: 12px;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}
main p {
  font-size: 48px;
}
main p #val1, main p #val2 {
  font-family: "Suisse Works";
  font-style: italic;
  display: inline-block;
  text-align: center;
}
main button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px 24px;
  cursor: pointer;
}
/*# sourceMappingURL=index.5f845c11.css.map */
