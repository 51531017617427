body {
  margin: 0;
}

body, button, input {
  font-family: 'Suisse Int\'l';
}

header {
  margin: 10px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0; left: 0; right: 0;

  a {
    color: currentColor;
    text-decoration: none;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 100%;
      height: 1px;
      background-color: currentColor;
      transition: .5s cubic-bezier(0.075, 0.82, 0.165, 1);
      pointer-events: none;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      height: 1px;
      opacity: .2;
      background-color: currentColor;
      pointer-events: none;
    }

    &:hover::before {
      right: 0;
    }
  }

  h1 {
    font-size: 14px;
    font-weight: normal;
    margin: 0;
  }

  nav {
    font-size: 14px;

    a {
      margin-left: 12px;
    }
  }
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;

  p {
    font-size: 48px;

    #val1, #val2 {
      font-family: 'Suisse Works';
      font-style: italic;
      display: inline-block;
      // width: 240px;
      text-align: center;
    }
  }

  button {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px 24px;
    cursor: pointer;
  }
}
